import PropTypes from 'prop-types'
import * as styles from './arcana.module.css'

import React, { useState } from 'react'
import { randomBetween } from '../../../utils/random'
import { ClientOnly } from '../../components/client-only/client-only'

import { useInterval } from '../../../utils/useInterval'

export const Arcana = () => {
  const [words, setWords] = useState(generateWords())

  return (
    <section className={styles.parchment}>
      <section className={styles.content}>
        <ClientOnly>
          {words.map((w, i) => (
            <ArcanaWord word={w} key={i} />
          ))}
        </ClientOnly>
      </section>
    </section>
  )
}

const ArcanaWord = ({ word }) => {
  const [visibleWord, setVisibleWord] = useState(word.text)
  const [changes, setChanges] = useState(randomBetween(10, 40))
  if (word.type === 'code') {
    useInterval(() => {
      if (changes > 0) {
        setChanges(changes - 1)
        setVisibleWord(generateWord(word.text.length).text)
      } else {
        setVisibleWord(word.text)
      }
    }, 50)
  }
  return <span className={styles[word.type]}>{visibleWord}</span>
}

//type Type = 'arcane' | 'code'
//interface Word {
//type: Type
//text: string
//}

Arcana.propTypes = {
  siteTitle: PropTypes.string,
}

Arcana.defaultProps = {
  siteTitle: ``,
}

function generateWords() {
  const numberOfWords = 40
  const words = Array(numberOfWords)
    .fill(0)
    .map((_) => (Math.random() > 0.8 ? getKeyword() : generateWord()))
  return words
}

function randomNumberOfLetters() {
  return randomBetween(3, 7)
}

function generateWord(numberOfLetters = randomNumberOfLetters()) {
  const text = Array(numberOfLetters)
    .fill(0)
    .map((_) => generateLetter())
    .join('')
  return { text, type: 'arcane' }
}

function getKeyword() {
  const keywords = [
    'function',
    'noremap',
    'abbr',
    'def',
    'number',
    'class',
    ':help',
    ':%s',
    ':del',
  ]
  const n = keywords.length
  const index = Math.floor(Math.random() * (n - 1))
  return { text: keywords[index], type: 'code' }
}

function generateLetter() {
  const letterCharCode = Math.floor(65 + Math.random() * (124 - 65))
  return String.fromCharCode(letterCharCode)
}
