export function getRandomInteger({ min, max }) {
  return min + Math.floor(Math.random() * (max - min))
}

export function randomBetween(min, max) {
  return Math.round(min + Math.random() * (max - min))
}

export function randomFromSelection(selection) {
  const index = randomBetween(0, selection.length - 1)
  return selection[index]
}
