import React from 'react'
import * as styles from './Bio.module.css'

import { StaticImage } from 'gatsby-plugin-image'

const Bio = () => 
  (
    <div className={styles.bio}>
      <StaticImage
        src="./jaime-avatar-bio.jpg"
        alt={`Jaime González García`}
        title="Jaime González García"
        width={150}
        height={150}
        quality={100}
        layout="fixed"
        className={styles.avatar}
        />
      <p>
        Written by{' '}
        <strong>
          <a href="https://www.linkedin.com/in/jaimegonzalezgarcia">
            Jaime González García
          </a>
        </strong>{' '}
        , dad, husband, software engineer, ux designer, amateur
        pixel artist, tinkerer and master of the arcane arts. You can also find him <a href="https://twitter.com/vintharas">
          on Twitter
        </a> jabbering about random stuff.
        <a
          style={{ visibility: 'hidden', position: 'absolute' }}
          href="https://plus.google.com/110133281086355413433?rel=author"
          title="Jaime González García"
        >
          <span className="fn">Jaime González García</span>
        </a>
      </p>
    </div>
  )

export default Bio
