import React from 'react'
import * as styles from './copyright.module.css'

export class Copyright extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <aside className={styles.copyright}>
        <p>
          © {currentYear} Copyright Jaime Gonzalez Garcia{' '}
          <span className={styles.star}>*</span>. All rights reserved.
        </p>
        <p className={styles.description}>
          {' '}
          <span className={styles.star}>*</span>
          Half-elven ranger, deadly archer, master of the two-handed sword and
          dual scimitar wielder, protector of Veleiron, Falcon of the Crepuscle,
          silent death of the swamps of Zulk....
        </p>
      </aside>
    )
  }
}
