import React from 'react'
import * as styles from './Layout.module.css'
import './font-face.css'
import './styles.css'

import { Arcana } from './components/arcana/arcana'
import { Copyright } from './components/copyright/copyright'


const Layout = ({children}) => {
  return (
    <div className={`${styles.container} wizardsusevim`}>
      <Arcana />
      <section className={styles.content}>
        <section>{children}</section>
        <Copyright />
      </section>
    </div>
  )
}

export default Layout
