// extracted by mini-css-extract-plugin
export var article = "ChapterTemplate-module--article--1ea1a";
export var basecolor = "#2e9cff";
export var container = "ChapterTemplate-module--container--0bcb4";
export var content = "ChapterTemplate-module--content--fa4bb";
export var date = "ChapterTemplate-module--date--9c20d";
export var footer = "ChapterTemplate-module--footer--2b135";
export var header = "ChapterTemplate-module--header--bbb1f";
export var highlightcolor = "#00cc6d";
export var next = "ChapterTemplate-module--next--731d0";
export var pagination = "ChapterTemplate-module--pagination--23315";
export var paginationitem = "ChapterTemplate-module--paginationitem--fca10";
export var readingtime = "ChapterTemplate-module--readingtime--cbc18";
export var small = "ChapterTemplate-module--small--16466";