import React from 'react'
import * as styles from './button.module.css'
import ButtonBase, {ButtonPagination as ButtonPaginationBase} from '../../../components/Button'

export const Button = ({ children, href }) => (
  <ButtonBase green currentText to={href} className={styles.button}>
    {children}
  </ButtonBase>
);

export const ButtonPagination = ({children, ...rest}) => (
  <ButtonPaginationBase {...rest} className={styles.paginationButton}>
    {children}
  </ButtonPaginationBase>
);
