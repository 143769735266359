import React, { useState, useEffect } from 'react'

/**
 * This whole snippet is a hack so that server side render will work nicely
 * with hydration and React won't break the arcana words. The way we achieve
 * this is by not rendering anything in the backend, and letting whatever is
 * wrapped in this component be client-side rendered only.
 */
export const ClientOnly = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) return null

  return <div {...delegated}>{children}</div>
}
