import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import get from 'lodash/get'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from './Layout'
import { ButtonPagination } from './components/button/button'
import Bio from '../components/bio/Bio'
import AgnosticLink from '../components/AgnosticLink'
import * as styles from './ChapterTemplate.module.css'
import {Arcana} from './components/arcana/arcana'

import { rhythm } from '../utils/typography'

class ChapterTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { mdx } = data

    const { previous, next } = this.props.pageContext
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const { description, chapter } = mdx.frontmatter
    const { excerpt } = mdx

    return (
      <Layout>
        <main className={styles.container}>
          <article className={styles.article}>
            <SEO
              title={`${mdx.frontmatter.title} | ${siteTitle}`}
              description={description || excerpt}
              keywords={[`vim`, `neovim`, `programming`]}
              faviconUrl="/wizards-use-vim/vim-icon.png"
            />

            <header className={styles.header}>
              <small className={styles.small}>Chapter {chapter}.</small>
              <small className={styles.small} style={{ float: 'right' }}>
                <AgnosticLink href="/wizards-use-vim/table-of-contents">
                  Table of Contents
                </AgnosticLink>
              </small>
              {
              // TODO: Add back cheatsheet 
              //<small className={styles.small} style={{ float: 'right' }}>
                //<AgnosticLink href="/boost-your-coding-fu-with-vscode-and-vim/cheatsheet">
                  //Cheatsheet
                //</AgnosticLink>
              //</small>
            }
              <h1 style={{ paddingBottom: rhythm(1 / 2) }}>
                {mdx.frontmatter.title}
              </h1>
            </header>
            <div className={styles.content} >
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </div>
            <footer className={styles.footer} style={{ marginTop: rhythm(2) }}>
              <ul className={styles.pagination}>
                {previous && (
                  <li className={styles.paginationitem}>
                    <ButtonPagination
                      to={previous.fields.slug}
                      rel="prev"
                      black
                    >
                      ← Previous: {previous.frontmatter.title}
                    </ButtonPagination>
                  </li>
                )}

                {next && (
                  <li className={styles.paginationitem + ' ' + styles.next}>
                    <ButtonPagination
                      to={next.fields.slug}
                      rel="next"
                      black
                    >
                      Next: {next.frontmatter.title} →
                    </ButtonPagination>
                  </li>
                )}
              </ul>
            </footer>
            <hr style={{ marginBottom: rhythm(1) }} />
            <Bio/>
            <hr style={{ marginBottom: rhythm(1) }} />
          </article>
        </main>
        <Arcana/>
      </Layout>
    )
  }
}

export default ChapterTemplate

// Changed query to $id and see whether that works instead of the slug...
export const pageQuery = graphql`
  query WizardsUseVimBookChapterBySlug($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }){
      id
      body
      frontmatter {
        title
        date
        categories
        description
        chapter
        book
      }
    }
  }
`
